import angularModule from 'LearnerProjects/angularModule/scripts/learner_projects_module';

angularModule.factory('LearnerProject', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('learner_projects');
            this.alias('LearnerProject');
            this.setIdProperty('id');

            this.CAPSTONE_PROJECT_TYPE = 'capstone';
            this.STANDARD_PROJECT_TYPE = 'standard';
            this.PRESENTATION_PROJECT_TYPE = 'presentation';

            // EMBA/MBA current - duplicated on server model
            this.PASSING_SCORES_MAP = {
                capstone: 3,
                standard: 2,
                presentation: 2,
            };

            Object.defineProperty(this.prototype, 'titleWithTag', {
                get() {
                    return this.tag ? `${this.title} [${this.tag}]` : this.title;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'sortKey', {
                get() {
                    const matches = (this.tag && this.tag.match(/[0-9]+/)) || ['0'];
                    const tagNumber = matches[0];
                    return `${this.title}${tagNumber.padStart(4, 0)}`;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isCapstoneProject', {
                get() {
                    return this.project_type === this.constructor.CAPSTONE_PROJECT_TYPE;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isStandardProject', {
                get() {
                    return this.project_type === this.constructor.STANDARD_PROJECT_TYPE;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isPresentationProject', {
                get() {
                    return this.project_type === this.constructor.PRESENTATION_PROJECT_TYPE;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'validStatusOptionsForProgressRecords', {
                get() {
                    const statuses = [{ value: 'unsubmitted', label: 'unsubmitted' }]; // valid for all project types

                    if (this.isStandardProject || this.isCapstoneProject) {
                        statuses.push(
                            ...[
                                {
                                    value: 'submitted',
                                    label: 'submitted',
                                },
                                {
                                    value: 'plagiarized',
                                    label: 'plagiarized',
                                },
                                {
                                    value: 'plagiarized_resubmission_ok',
                                    label: 'plagiarized resubmission ok',
                                },
                                {
                                    value: 'plagiarized_resubmitted',
                                    label: 'plagiarized resubmitted',
                                    legacy: true,
                                },
                                {
                                    value: 'plagiarized_resubmission_not_ok',
                                    label: 'plagiarized resubmission not ok',
                                    legacy: true,
                                },
                                {
                                    value: 'delayed_grading',
                                    label: 'delayed grading',
                                    legacy: true,
                                },
                                {
                                    value: 'will_not_submit',
                                    label: 'will not submit',
                                    legacy: true,
                                },
                            ],
                        );
                    } else if (this.isPresentationProject) {
                        statuses.push(
                            ...[
                                {
                                    value: 'live_presentation_scheduled',
                                    label: 'live presentation scheduled',
                                },
                                {
                                    value: 'live_presentation_complete',
                                    label: 'live presentation complete',
                                },
                                {
                                    value: 'video_presentation_submitted',
                                    label: 'video presentation submitted',
                                },
                            ],
                        );
                    }
                    return statuses;
                },
                configurable: true,
            });

            return {
                // duplicated on server model, varies between EMBA and MBA
                getScoringWeight(cohort) {
                    if (this.isCapstoneProject) {
                        return 4;
                    }
                    if (this.isStandardProject) {
                        return cohort.isMBA && cohort.startDate < new Date('2021/08/09') ? 4 : 2;
                    }
                    if (this.isPresentationProject) {
                        return 1;
                    }
                    return undefined;
                },

                // duplicated on server model
                getPassingScore(cohort) {
                    // Standard projects can have varying passing scores, dependent on the
                    // cohort's program_type and start_date
                    if (
                        this.isStandardProject &&
                        ((cohort.program_type === 'emba' && cohort.startDate <= new Date('2019/07/01')) ||
                            (cohort.program_type === 'mba' && cohort.startDate <= new Date('2019/07/29')))
                    ) {
                        return 1;
                    }
                    return this.constructor.PASSING_SCORES_MAP[this.project_type];
                },

                addProjectDocument(projectDocument) {
                    // Default the title
                    projectDocument.title = projectDocument.file_file_name;
                    this.project_documents.push(projectDocument);
                },

                removeProjectDocument(projectDocument) {
                    this.project_documents = _.without(this.project_documents, projectDocument);
                },
            };
        });
    },
]);
