import angularModule from 'LearnerProjects/angularModule/scripts/learner_projects_module';

angularModule.factory('ProjectProgressTimeline', [
    '$injector',
    function ($injector) {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('project_progress_timelines');
            this.alias('ProjectProgressTimeline');
            this.setIdProperty('id');
        });
    },
]);
