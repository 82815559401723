import { Route } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';

export const ROUTES = {
    CHOOSE_INSTITUTION: route('choose-institution', {}),
};

async function getLazyChooseInstitutionComponent(component: 'ChooseInstitutionPage') {
    const components = await import(/* webpackPrefetch: true */ './components/ChooseInstitutionPage');
    return { Component: components[component] };
}

export function getChooseInstitutionRoutes() {
    return (
        <Route
            path={ROUTES.CHOOSE_INSTITUTION.relativePath}
            lazy={() => getLazyChooseInstitutionComponent('ChooseInstitutionPage')}
        />
    );
}
