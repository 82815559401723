import angularModule from 'IguanaExtensions/angularModule/scripts/iguana_extensions_module';
import { OnetrustCookieHelper } from 'Onetrust';

angularModule.run([
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const EventLogger = $injector.get('EventLogger');
        const EventBundle = $injector.get('EventLogger.EventBundle');

        const save = Iguana.prototype.save;
        Iguana.prototype.save = function (meta, config) {
            // in order to make sure that events are consistent with
            // other records, clear the event buffer whenever we
            // save progress
            if (!this.isA(EventBundle)) {
                meta = meta || {};
                meta.event_bundle = EventLogger.tryToClearBuffer(false, 'json');
                meta.consent_disabled_integrations = OnetrustCookieHelper.getDisabledIntegrationsObject();
            }

            return save.apply(this, [meta, config]);
        };
    },
]);
