import angularModule from 'LearnerProjects/angularModule/scripts/learner_projects_module';

angularModule.factory('ProjectProgress', [
    '$injector',
    function ($injector) {
        const Iguana = $injector.get('Iguana');
        const ProjectProgressTimeline = $injector.get('ProjectProgressTimeline');

        return Iguana.subclass(function () {
            this.alias('ProjectProgress');
            this.setIdProperty('id');

            Object.defineProperty(this.prototype, 'scoreOptionValue', {
                get() {
                    if (this.waived) {
                        return 'waived';
                    }
                    if (this.marked_as_passed) {
                        return 'marked_as_passed';
                    }
                    if (this.score === undefined) {
                        return null;
                    }
                    return this.score;
                },
                set(val) {
                    if (val === 'waived') {
                        this.waived = true;
                        this.marked_as_passed = false;
                        this.score = null;
                    } else if (val === 'marked_as_passed') {
                        this.waived = false;
                        this.marked_as_passed = true;
                        this.score = null;
                    } else if (val === null || val === undefined) {
                        this.waived = false;
                        this.marked_as_passed = false;
                        this.score = null;
                    } else {
                        this.waived = false;
                        this.marked_as_passed = false;
                        val = Number(val);
                        this.score = Number(val);

                        // If we are explicitly setting the `score` column, we should
                        // automatically upated the `status` column to submitted if it
                        // does not yet have a value.
                        this.status = this.status || 'submitted';
                    }
                },
            });

            Object.defineProperty(this.prototype, 'hasScoreSet', {
                get() {
                    return this.scoreOptionValue || this.scoreOptionValue === 0;
                },
            });

            return {
                // Duplicated on server model
                unpassedForProject(project, cohort) {
                    if (angular.isDefined(this.score)) {
                        // If score is present, a ProjectProgress should be considered unpassed
                        // if the score is less than the corresponding project's passing_score.
                        // Presentation projects also require id_verified, so records with a
                        // passing score but !id_verified should be considered unpassed.
                        return (
                            (project.project_type === project.constructor.PRESENTATION_PROJECT_TYPE &&
                                !this.id_verified) ||
                            this.score < project.getPassingScore(cohort)
                        );
                    }
                    return !this.waived && !this.marked_as_passed;
                },

                getCorrespondingTimelineEvents() {
                    return ProjectProgressTimeline.show(this.id).then(
                        response => (response.result && response.result.events) || [],
                    );
                },
            };
        });
    },
]);
